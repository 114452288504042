export enum SalesStatus {
  POTENTIAL = "POTENTIAL",
  FIRST_CONTACT = "FIRST_CONTACT",
  INFO_SHARED = "INFO_SHARED",
  OFFER = "OFFER",
  DEMO = "DEMO",
  TRAIL = "TRAIL",
  FOLLOW_UP = "FOLLOW_UP",
  TO_INVOICE = "TO_INVOICE",
  INVOICED = "INVOICED",
  PAID = "PAID",
  MISSED = "MISSED",
  OLD = "OLD",
}

export const SalesStatusArray = [
  SalesStatus.POTENTIAL,
  SalesStatus.FIRST_CONTACT,
  SalesStatus.INFO_SHARED,
  SalesStatus.OFFER,
  SalesStatus.DEMO,
  SalesStatus.TRAIL,
  SalesStatus.FOLLOW_UP,
  SalesStatus.TO_INVOICE,
  SalesStatus.INVOICED,
  SalesStatus.PAID,
  SalesStatus.MISSED,
  SalesStatus.OLD,
]
