<template>
  <Dropdown
    v-model="modelValue"
    :options="options"
    @change="$emit('update:modelValue', modelValue)"
  />
</template>

<script>
export default {
  name: "ComputerCloneDropdown",
  props: {
    modelValue: {
      type: String,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const options = [
      "219_Master_373T_AQS6-Win10__77SN_20240917",
      "218_Master_LT5430_AQS6-Win11_RLT_20240902",
      "217_Master_OP7010_AQS6-Win11_20240620",
      "216_Master_373T_AQS6-Win10__OBY01_20240611",
      "215_Master_Lenovo_IdeaPad._Dental_AQS6_2024-05-21",
      "214_Master_OP5000_AQS6-Win11_20240502",
      "213_Master_LT5420_AQS6-Win11_20240308",
      "212_Master_373T_AQS6_Win10_20240301",
      "210_Master_LT5430_AQS6-Win11_20240215",
      "209_Master_OP3000m_AQS6-Win11_20240215",
      "208_Master_OP3080_AQS6-Win11_20240215",
      "207_Master_OP3000_AQS6-Win11_20240209",
      "206_Master_LT5430_AQS6-Win11_20240209",
      "205_Master_OP3060m_AQS6-Win11_20240209",
      "204_Master_OP3080m_AQS6-Win11_20240130",
      "201_Master_OP7010_AQS6-Win11_20240101",
      "200_Master_LT5430_AQS6-Win11_20240101",
      "131_Master_CF54mk1_CLA9_20231220",
      "130_Master_Lenovo_IdeaPad_Dental_AQS6_2023-10-09",
      "129_Master_OPTIPLEX 5000_DR_AQS_2023-11-7",
      "127_MASTER_OP7010_Win11_01-12-2023",
      "126_MASTER_CF54mk1_AQS_Win10_2023-11-24",
      "122_AQS_DTR_Master_31-07-2023",
      "115_Master_DELL 5430_AHCI_DR_AQS6_2023-6-21",
      "112_OPTIPLEX 3000 mini__AQS6_2023-5-24",
      "109_Master_DELL 5420_DR_WIN11_AQS6_2023-03-20",
      "107_Master_OPTIPLEX 5000_DR_AQS_2023-3-17",
      "102_Master_OPTIPLEX 7020_CR XG1_9.0_2023-1-3",
      "101_Master_OPTIPLEX 3000_DR_AQS_2022-12-02",
      "99_Master_OPTIPLEX 3090_AHCI_AQS6_2022-9-30",
      "98_Master_CFC2 MK2_DR_AQS5_WIN10_21H2_2022-8-10",
      "97_Master_DELL 5430_AHCI_DR_AQS5_2022-7-27",
      "96_Master_DELL 5430_AHCI_DR_AQS5_2022-7-25",
      "94_Master_DELL 5430_DR_AQS6_2022-6-13",
      "92_Master_OPTIPLEX 3080_AHCI_AQS5_2022-4-28",
      "91_Master_DELL 5430_DR_AQS5_AHCI_2022-4-26",
      "89_Master_OPTIPLEX 3050_CR CAPSULA_9.0_2021-12-23",
      "87_Master_CFC2 MK2_DR_AQS5_2021-12-16",
      "86_Master_OPTIPLEX 3050_CR CAPSULA_9.0_2021-11-17",
      "84_Master_OPTIPLEX 3080_DR_AQS5_2021-11-10",
      "82_Master_DELL 5420_DR_AQS5_2021-11-10",
      "80_Master_OPTIPLEX 3050_CR PRIMA_9.0_2021-4-21",
      "79_Master_DELL 3301__AQS5_2021-4-7",
      "78_Master_GETAC S410_DR_AQS5_2021-3-30",
      "76_Master_CF53 MK3_DR_AQS5_2021-3-17",
      "75_Master_OPTIPLEX 3080_DR_AQS5_2021-1-17",
      "74_Master_OPTIPLEX 3080 mini_DR_AQS5_2021-1-15",
      "73_Master_DELL 5420_DR_AQS5_2021-1-11",
      "FZ-Win1020H2",
      "71-BOS_OPTIPLEX 7020_CR XG1_9.0_2020-10-1",
      "69_Master_FZ55_DR_AQS_2020-9-30",
      "68_Master_OPTIPLEX 3050_CR CAPSULA_9.0_2020-8-21",
      "67_Master_OPTIPLEX 3040_CR PRIMA_9.0_2020-8-21",
      "66_Master_CF54 MK1_DR_9.0_2020-8-18",
      "63_Master_CF54 MK1_DR_AQS_2020-8-14",
      "61_Master_OPTIPLEX 3060_DR_AQS_2020-8-14",
      "60_Master_OPTIPLEX 3070_DR_AQS_2020-8-13",
      "59_Master_CF54 MK2__AQS_2020-8-13",
      "58_Master_CF54 MK3__AQS_2020-8-13",
      "57_OPTIPLEX 3040_CR PRIMA_9.0_2020-7-20",
      "56_Master_Acer_TMX3410_2020-05-06",
      "55_THINKPAD E590__AQS_2020-2-17",
      "54_Master OPTIPLEX 3040_CLA_2020-02-11",
      "52_OPTIPLEX 3070_DR_AQS_2019-12-9",
      "51_Master_IDEAPAD D330__AQS_2019-11-7",
      "50_Master_CFC2 MK2__AQS_2019-10-8",
      "49_Master_IDEAPAD D330_AQS_2019-9-6",
      "48_Master_Lenovo E50_DR_AQS_2019-9-3",
      "47-OAS_UT-MA6_9.0_2019-8-27",
      "46_UT-MA6_AQS_2019-8-27",
      "45_Master_CF54 MK3_DR_AQS_2019-8-14",
      "44_OPTIPLEX 3060_CLEAN W10_2019-8-6",
      "43_Master_HP REVOLVE G2 Touch_DR_AQS_2019-7-24",
      "42_Master_OPTIPLEX 7020_DR_AQS_2019-7-24",
      "41_Master_OPTIPLEX 7010_DR_AQS_2019-7-24",
      "40_OPTIPLEX 7010_CAPSULAMASTER_9.0_2019-7-17",
      "39_OPTIPLEX 7010_CR PRIMA T_9.0_2019-7-22_VLI",
      "38_Master_HP REVOLVE G2 Touch_DR_AQS_2019-6-28",
      "37-RGR_HP 850 Touch_CR PRIMA T_8.001_2019-6-28",
      "36_Master_OPTIPLEX 3060__AQS_2019-6-17",
      "35_Master_CFC2 MK2_AQS_2019-6-12",
      "34_Master3050CapsulaX+G35-VSOL531-20181010-MSRT",
      "33_Master_CF54 MK2__AQS_2019-6-4",
      "32_Master_CF54 MK1__AQS_2019-6-4",
      "31-CVB_THINKPAD L390_DR_AQS_2019-5-22",
      "30_Master_CF54 MK3_AQS_2019-4-30",
      "29_Master_OPTIPLEX 3040_AQS_2019-4-03",
      "28_Master_CF54 MK1_AQS_2019-3-11",
      "27_Master_CF54 MK3_AQS_2019-3-11",
      "26_Master_OPTIPLEX 3050 mini_AQS_2019-3-8",
      "25_Master_OPTIPLEX 3050__AQS_2019-3-6",
      "24_Master_CF54 MK2_AQS_NoCLA_2019-3-1",
      "23_Master_CF54 MK3_AQS_NoCLA_2019-2-28",
      "22_mag weg",
      "21_CF54 MK3_AQS_NoCLA_2019-2-19",
      "20_Master_OPTIPLEX 3050_AQS_NoCLA_2019-2-18",
      "19_OPTIPLEX 3050_AQS_NoCLA_2019-2-15",
      "18_CF54 MK2_AQS_NoCLA_2019-2-15",
      "17_CF54 MK2_DR_9.0_RUPC16.6_2019-2-5",
      "16_CF54 MK2_DR_9.0_RUPC16.6_2019-1-25",
      "14_CF54 MK2_DR_9.0_2019-1-24",
      "12_CF54MKII-VSOL492-20180528_full_b1_s1_v1",
      "11-ORB_Lenovo E50__V9.0_2019-1-15",
      "10_Lenovo E50__V9.0_2019-1-15",
      "9_Master3050X+G35-VSOL600-20181123-MSRT_RUPC7-full_b1_s1_v1_full_b1_s1_v1",
      "8_LNO MK3 20190105_full_b1_s1_v1",
      "7_CF54 MK3_DR_V9.0_DR16.6_2019_1_9_full_b1_s1_v1",
      "6_CF54 MK3_DR_V9.0_DR3.4_2019_1_9_full_b1_s1_v1",
      "4_Master3050X-MINI-FR+G35-VSOL500-20180131-MSRT_full_b1_s1_v1",
      "3_Win7x64-Blanco-AllDriversOK-VSTKInstalled_full_b1_s1_v1",
      "2_Master3050X-MINI-SERVER-NoCLA-VSOL600-20181119-MSRT_full_b1_s1_v1",
      "1_Master_CF54 MK3_DR_V9.0_2019-1-3",
    ]

    return {
      options,
    }
  },
}
</script>
