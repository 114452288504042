import Company from "@/models/Company"

export class Organization {
  coc: string
  code: string
  comments: string
  commercial: string
  company: Company
  createdTime: Date
  deleted: boolean
  extraCommunity: boolean
  id: string
  internalName: string
  intraCommunity: boolean
  language: string
  name: string
  network: any
  organizationType: any
  pms: string
  pw: string
  size: number
  subscription: string
  updatedTime: Date
  vat: string
  via: string
  website: string
}

export enum OrganizationTypes {
  EQ = "EQ",
  SA = "SA",
  HB = "HB",
  OT = "OT",
}

export enum OrganizationSizes {
  PRACTICE_1_PERSON = "PRACTICE_1_PERSON",
  PRACTICE_2_PERSON = "PRACTICE_2_PERSON",
  PRACTICE_MULTIVET = "PRACTICE_MULTIVET",
  CLINIC = "CLINIC",
}

export enum OrganizationNetworks {
  BOA = "BOA",
  BEVET = "BEVET",
  IVC = "IVC",
  ANICURA = "ANICURA",
  CVS = "CVS",
  EVIDENSIA = "EVIDENSIA",
  NESTO = "NESTO",
  ALTANO = "ALTANO",
  EQUINE_CARE_GROUP = "EQUINE_CARE_GROUP",
}

export enum OrganizationSubscriptionTypes {
  BRONZE = "BRONZE",
  SILVER = "SILVER",
  GOLD = "GOLD",
  FIRST_YEAR = "FIRST_YEAR",
}
